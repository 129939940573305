import React, { useEffect, useState } from 'react';

function App() {
  //constant variables
  const [status,setStatus] = useState('Redirecting..');
  const hostname = window.location.hostname;

  useEffect(() => {
    //dynamic variables
    let [domainStart,domain, domainEnding] = hostname.split('.');
    let deviderCount = (hostname.match(/\./g) || []).length;

    //fix for www.
    if (deviderCount===1) {
      domain=domainStart;
    }

    //determine if the site has a predefined url, if not redirect to [domain].com;
    switch (domain.toLowerCase()) {
    
      case "coasttechnology":
        domain = "dealershiptoolkit";
        break;
    
      default:
        break;
    
    }

    //commit to redirect
    setStatus(`Redirecting to https://www.${domain}.com...`);
    window.location.href = `https://www.${domain}.com/`;
  }, []);

  return (
    <div>
      <h1>{status}</h1>
    </div>
  );
}

export default App;
